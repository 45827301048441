<template>
  <div class="search section">
    <div class="section-header">
      <div class="section-header--bread">
        <router-link to="/">Головна</router-link>
        <p>|</p>
        <a>Пошук</a>
      </div>
      <div class="search-input">
        <input
          type="text"
          name="search"
          id="search"
          placeholder="Номер або назва документу"
          v-model="search"
          v-on:keyup.enter="submit"
        />
        <router-link :to="`/search?query=${search}`">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 513.28 513.28"
          >
            <path
              d="M495.04,404.48L410.56,320c15.36-30.72,25.6-66.56,25.6-102.4C436.16,97.28,338.88,0,218.56,0S0.96,97.28,0.96,217.6
			s97.28,217.6,217.6,217.6c35.84,0,71.68-10.24,102.4-25.6l84.48,84.48c25.6,25.6,64,25.6,89.6,0
			C518.08,468.48,518.08,430.08,495.04,404.48z M218.56,384c-92.16,0-166.4-74.24-166.4-166.4S126.4,51.2,218.56,51.2
			s166.4,74.24,166.4,166.4S310.72,384,218.56,384z"
            />
          </svg>
        </router-link>
      </div>
    </div>

    <Loader v-if="load" />

    <div class="searhc-results section" v-else>
      <div class="searhc-results-head">
        <p>
          За вашим запитом знайдено документів: <span>{{ total }}</span>
        </p>
      </div>

      <div class="searhc-results-body">
        <div class="results-list"></div>
        <div class="results-items">
          <ul v-for="(section, index) in results" :key="index">
            <p class="results-items-header">{{ section.num.title }}</p>
            <li v-for="(result, index) in section.data" :key="index">
              <a
                :href="
                  getLink(
                    section.num.url,
                    result.номер,
                    result.дата || result.опубліковано || result.прийнято,
                    result.id
                  )
                "
                class="document-item"
              >
                <p class="number">{{ result.номер }}</p>
                <p>{{ result.назва }}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ref, computed, onBeforeMount, watch } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";

  import { inLink } from "../components/functions/link";
  import dates from "../store/lists/dates";
  import menu from "../store/lists/menu";

  import axios from "axios";
  import Loader from "../components/global/Loader.vue";

  export default {
    components: { Loader },
    name: "Search",

    setup() {
      const route = useRoute();
      const router = useRouter();
      const store = useStore();
      const query = computed(() => route.query.query);
      const search = ref("");
      const load = ref(true);

      const submit = () => {
        router.push({ path: "search", query: { query: search.value } });
      };

      onBeforeMount(() => {
        if (query.value && query.value != "") {
          search.value = query.value.toLowerCase();
          fetchData();
        } else {
          load.value = false;
        }
      });

      watch(
        () => query.value,
        () => {
          if (query.value != "") {
            total.value = 0;
            results.value = [];
            fetchData();
          }
        }
      );

      const getLink = (c, n, d, i) => inLink(c, n, d, i);

      //Fetch data

      const results = ref([]);
      const total = ref(0);

      const fetchData = async () => {
        load.value = true;
        try {
          for (let i = 1; i < 6; i++) {
            let get = await axios.get(dates.all[i]),
              header = get.data.values[0],
              rows = get.data.values.slice(1),
              formated = [];

            for (const row of rows) {
              const formattedRow = {};

              header.forEach((e, i) => {
                formattedRow[e.toLowerCase()] = row[i];
              });

              formated.push(formattedRow);
            }

            let data = formated.filter(
              (e) => e.номер.includes(search.value) || e.назва.toLowerCase().includes(search.value)
            );

            total.value += data.length;

            if (data.length != 0)
              results.value.push({
                num: menu.name.find((e) => e.url === i),
                data: data.sort((a, b) => parseFloat(a.номер) - parseFloat(b.номер)),
              });

            if (i === 5) load.value = false;
          }
        } catch (e) {
          store.commit("setModal", "error");
          console.log(e);
          load.value = false;
        }
      };

      return { search, submit, load, total, results, getLink };
    },
  };
</script>
<style lang="scss">
  .search {
    .search-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $black;
      border-bottom: 1px solid #000;
      margin: 1em 0;

      input {
        position: relative;
        font-size: 2em;
        width: 100%;
      }

      a {
        &:hover svg {
          fill: $acs;
        }
        svg {
          position: relative;
          width: 50px;
          height: 50px;
          fill: $black;

          @media screen and (max-width: 721px) {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .searhc-results {
      .searhc-results-head {
        span {
          color: $acs;
        }
      }

      .results-items-header {
        position: relative;
        &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-image: linear-gradient(135deg, #43cbff 10%, #9708cc 100%);
        }
      }
    }
  }
</style>
